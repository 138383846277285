<template>
  <div class="userdata">
    <div class="top">
      <div class="top-text">基础资料</div>
      <div class="top-header">
        <div class="header-text">头像</div>
        <div class="imgbox">
          <img :src="headerImg" alt="" class="img">
        </div>
      </div>
    </div>
    <div class="line-box">
      <img src="@/assets/images/user/line.png" alt="" class="line">
    </div>
    <div class="center">
      <div class="center-text">保密资料</div>
      <div class="phonebox">
        <div class="title">手机号</div>
        <div class="phone">{{phone}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      phone:"",  // 电话号码
      headerImg:"", // 用户的头像信息
    }
  },
  mounted(){
    document.querySelector('body').setAttribute('style', 'background-color:#ffffff')
    this.phone = sessionStorage.getItem("phone")
    this.headerImg = localStorage.getItem("headerImg")
  },
}
</script>
<style lang="less" scoped>
.userdata{
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
.top{
  width: 92%;
  box-sizing: border-box;
  padding: 34px 0;
  margin: 0 auto;
  .top-text{
    font-size: 12px;
    color: #a7b2bf;
    margin-bottom: 16px;
  }
  .top-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    .header-text{
      font-size: 16px;
      color: #222222;
      font-weight: 500;
    }
    .imgbox{
      width: 56px;
      height: 56px;
      background-color: #9e9e9e;
      box-sizing: border-box;
      padding: 0 1px;
      .img{
        height: 56px;
        width: 54px;
        display: block;  
      }
    }
  }
}
.line-box{
  width: 90%;
  margin: 0 auto;
  .line{
  width: 100%;
  display: block;
  margin: 0 auto 34px ;
  height: 1px;
}
}
.center{
  width: 92%;
  box-sizing: border-box;
  margin: 0 auto;
  .center-text{
    font-size: 12px;
    color: #a7b2bf;
    margin-bottom: 16px;
  }
  .phonebox{
    display: flex;
    width: 100%;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    .title{
      font-size: 16px;
      color: #222222;
      font-weight: 500;
    }
    .phone{
      font-size: 14px;
      font-family: "bahn";
      color: #a7b2bf;
    }
  }
}
}
</style>